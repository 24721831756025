import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import Slider, { CustomArrowProps } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.scss';

interface Props {
  gallery: any;
}

const BLOCK_CLASSNAME = 'carousel';
const MOBILE_BREAKPOINT = 1024;

const PrevArrow = ({ onClick, className }: CustomArrowProps) => (
  <div className={classNames(`${BLOCK_CLASSNAME}__arrow-prev`, className)} onClick={onClick} />
);

const NextArrow = ({ onClick, className }: CustomArrowProps) => (
  <div className={classNames(`${BLOCK_CLASSNAME}__arrow-next`, className)} onClick={onClick} />
);

const renderImages = (gallery: any): ReactNode =>
  gallery.map((props: any, key: number) => {
    const image = getImage(props.gatsbyImageData);
    if (image) {
      return (
        <GatsbyImage key={key} image={image} loading="lazy" alt={props.title} objectFit="contain" />
      );
    }
    return null;
  });

const isBrowser = () => typeof window !== 'undefined';
const getInitialMobileValue = (): boolean =>
  isBrowser() ? window.innerWidth <= MOBILE_BREAKPOINT : false;

const Carousel: FC<Props> = ({ gallery }: Props) => {
  const [mobile, setMobile] = useState<boolean>(getInitialMobileValue);

  const handleWindowSizeChange = (): void => {
    setMobile(window.innerWidth <= MOBILE_BREAKPOINT);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className={BLOCK_CLASSNAME}>
      <div>
        {mobile ? renderImages(gallery) : <Slider {...settings}>{renderImages(gallery)}</Slider>}
      </div>
    </div>
  );
};

export default Carousel;
