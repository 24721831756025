import Layout from '../components/Layout';
import Carousel from '../components/Carousel/Carousel';
import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import 'react-image-lightbox/style.css';
import '../styles/main.scss';

type Props = PageProps<any>;

const Index: FC<Props> = ({ data }: Props) => {
  const homeGallery = data.contentfulGallery?.gallery;
  return (
    <Layout>
      <Carousel gallery={homeGallery} />
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    contentfulGallery(category: { eq: "Home" }) {
      gallery {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        title
      }
    }
  }
`;

export default Index;
